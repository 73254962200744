@use "styles/colors";
@use "styles/typography";
@use "styles/popup";
@use "styles/forms";

.noCompleted{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}

.form-container{
    margin-top: 24px;
    padding-top: 24px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px #CFCFCF;
    padding-bottom: 24px;
    padding-left: 10%;
}

.headerGroup{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
$kgo-color-background-onDark:#081217;

$kgo-color-neutral100: #FFFFFF;
$kgo-color-neutral200: #FBFBFB;
$kgo-color-neutral300: #E9E9E9;
$kgo-color-neutral400: #BBBBBB;
$kgo-color-neutral500: #696969;
$kgo-color-neutral700: #252525;
$kgo-color-neutral600: #393939;
$kgo-color-neutral800: #1F1F1F;

$kgo-color-base-orange400:#FFA260;
$kgo-color-base-orange500: #FF6116;
$kgo-color-base-orange600: #FF5100;
$kgo-color-base-orange700: #C75004;
$kgo-color-base-orange800: #8C2C00;

$neutral: #333333;
$accent: #054AB0;
$primary-hover: $kgo-color-base-orange700;
$primary-60: #1D71EC;
$secondary: #F97513;
$secondary-hover: #FFB21C;
$default: $kgo-color-base-orange500;
$light: #616C7A;
$white: #FFFFFF;
$color-background-02: #F2F2F2;
$color-text-02: #333333;
$color-text-disabled: #808080;
$color-button-disabled: #B3B3B3;
$color-semantic-primary: #E6E6E6;
$color-success-active:#006F51;
$color-success-disable: #32D2A7;
$color-error-active: #A63631;
$color-error-disable:#FF9590;
$error: #C9423C;


$kgo-color-base-blue100:#F0FAFF;
$kgo-color-base-blue200:#C9E6F3;
$kgo-color-base-blue300:#80B7CC;
$kgo-color-base-blue400:#45859D;
$kgo-color-base-blue500:#27677D;
$kgo-color-base-blue600:#0F4658;
$kgo-color-base-blue700:#002430;
$kgo-color-base-blue800:#061820;

$kgo-color-semantic-success100:#E2F8D4;
$kgo-color-semantic-success200:#89CF56;
$kgo-color-semantic-success300:#4BA564;

$kgo-color-semantic-warning100:#FBD87A;
$kgo-color-semantic-warning200:#F7C22E;
$kgo-color-semantic-warning300:#F2B400;

$kgo-color-semantic-error100:#F9AFB0;
$kgo-color-semantic-error200:#D73A3D;
$kgo-color-semantic-error300:#9C1C1E;

$kgo-color-semantic-info100:#E8F4FD;
$kgo-color-semantic-info200:#1175C9;
$kgo-color-semantic-info300:#005CAD;

$kgo-color-semantic-action100:#8FBBFF;
$kgo-color-semantic-action200:#0058E1;
$kgo-color-semantic-action300:#003B9C;
$kgo-color-semantic-action400:#002E7A;
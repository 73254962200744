@use "styles/colors";

.small-bgImage {
    height: 25%;
    object-fit: cover;
    object-position: 100% 30%;
    width: 25%;
    margin-bottom: -80px;
}

.medium-bgImage {
    height: 50%;
    object-fit: cover;
    object-position: 100% 30%;
    width: 50%;
    margin-bottom: -80px;
}

.large-bgImage {
    height: 100%;
    object-fit: cover;
    object-position: 100% 30%;
    width: 100%;
    margin-bottom: -80px;
}

.horizontal {
    height: 382px;
    width: 100%;
    position: relative;
}

.vertical {
    height: 100%;
    width: 30%;
    max-width: 448px;
}

.content {
    height: inherit;
    text-align: center;
    width: inherit;
}

.top {
    position: absolute;
    top: 0%;
}

.left {
    position: absolute;
    left: 0%;
}

.right {
    position: absolute;
    right: 0%;
}

.middle {
    position: absolute;
    top: 0;
    left: 0;
}

.primary {
    background-color: colors.$accent;
}

@media only screen and (max-width: 1300px) {
    .vertical {
        display: none;
    }
}
@use "styles/colors";

.container {
  padding: 56px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: colors.$kgo-color-neutral600;
}

.close-button:hover {
  color: colors.$kgo-color-base-orange500;
  cursor: pointer;
}

@use "styles/colors";
@use "styles/typography";
@use "styles/inputs";

.container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}

.error-text {
    margin-top: 8px;
    color: colors.$error;
    font-size: typography.$body-s;
}

.input-error {
    border: solid 1px colors.$error;
}

.input-error:focus-within {
    outline: none !important;
    border: solid 2px colors.$error;
}

.input-error:focus-within {
    outline: none !important;
    border: solid 2px colors.$error;
}

.visibility {
    margin-top: -40px;
    z-index: 2;
    align-self: flex-end;
    margin-right: 2%;
    Button {
        color: #BBBBBB;
        font-size: typography.$body-s;
    }
}

.label{
    @extend .kgo-font-md400;
}

@use "styles/colors";
@use "styles/typography";
@use "styles/popup";
.noSuggested{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}

.headerText{
    margin-left: 10px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
}

.bannerContainer{
   margin-bottom: 24px;
}

@use "styles/colors";
@use "styles/typography";

$width:auto;
$tripBackgroundColor:#061820;
.tripHeader{
	background-color:$tripBackgroundColor;
	border-radius: 12px;
	min-height:100px;
	width:$width;
	display:flex;
	justify-content:center;
	align-items:center;
    cursor: pointer !important;
	.title{
		display:flex;
		justify-content:center;
		color:white;
		margin-left:24px;
		margin-right:24px;
        @extend .kgo-font-lg600;
	}
	.gcircle{
		width:28px;
		height:28px;
		/* kngo/color/semantic/✅success/success200 */
		background: #89CF56;
		border-radius:50%;
	}
	.titleIcon{
		width:12px;
		height:12px;
		/* kngo/color/base/orange/orange600 */
		color: #FF5100;
		display:flex;
		align-items:center;
	}
	
}
.icon{
    color:colors.$kgo-color-base-orange600;
    transform: rotate(0deg);
    height: 24px;
    width: 24px;
}

.locationInfo{
    display: flex;
    align-items: center;
    position: relative;

    .tripLocations{
        padding-left: 40px;
    }

    .lineContainer{
        position: absolute;
        background-color: none;
        width: 0px;
        height: 50%;
        border-left: 2px dashed #FFFFFF;
    }

    .lineContainer::before{
        content:'';
        position: absolute;
        background-color: white;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        left:50%;
        transform: translateX(-60%);
    }
    .lineContainer::after{
        content:'';
        position: absolute;
        background-color: white;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        left:50%;
        bottom: 0px;
        transform: translateX(-60%);
    }

}

.locationName{
    @extend .kgo-font-xlg400;
    color: colors.$kgo-color-base-blue200;
}

.originDetails{
    display:flex;
    gap:12px;
    align-items: center;
    margin-bottom: 12px;
    margin-top:12px;
    .date{
        @extend .kgo-font-md200;
        color: colors.$kgo-color-neutral100;
    }
    .icon{
        color: colors.$kgo-color-neutral100;
        height: 12px;
        width: 12px;
        display: flex;
        align-items: center;
    }
}

.destinyLocationName{
    @extend .kgo-font-xlg400;
    color: colors.$kgo-color-base-blue200;
}


.originHeader{
    display: flex;
    gap: 12px;
    .iconOrigin{
        color: colors.$kgo-color-base-blue200;
        height: 12px;
        width: 12px;
    }
    .originTitle{
        color: colors.$kgo-color-base-blue200;
        @extend .kgo-font-md300;
        font-weight: 600 !important;
    }
}

.destinyHeader{
    display: flex;
    gap: 12px;
    margin-top:64px;
    .iconOrigin{
        color: colors.$kgo-color-base-blue300;
        height: 24px;
        width: 24px;
    }
    .originTitle{
        color: colors.$kgo-color-base-blue300;
        @extend .kgo-font-md300;
        font-weight: 600 !important;
    }
}

.destinyDetails{
    display:flex;
    gap:12px;
    align-items: center;
    margin-bottom: 12px;
    margin-top:12px;
    .date{
        @extend .kgo-font-lg400;
        color: colors.$kgo-color-neutral100;
    }
    .icon{
        color: colors.$kgo-color-neutral100;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
    }
}

.tripDetails{
    display:flex;
    gap:12px;
    align-items: center;
    margin-bottom: 12px;
    margin-top:12px;
    .date{
        @extend .kgo-font-md200;
        color: colors.$kgo-color-neutral100;
    }
    .icon{
        color: colors.$kgo-color-neutral100;
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
    }
}

.tripInfo{
    display: block;
    transform:scaley(1);
    height: auto;
    margin-top: 24px;
    padding:32px 24px 32px 24px;
    background-color:$tripBackgroundColor;
    border-radius: 12px;
    transform-origin:top;
    transition:transform .1s ease-in;
}

.expandable-toggler:checked ~ .tripInfo{
    transform:scaley(0);
    transform-origin:top;
    margin-top: 0px;
    padding:0px;
    height: 0px;
    background-color:$tripBackgroundColor;	
    border-radius: 12px;
    transition:transform .1s ease-in;
}

.icon{
    transition:all .2s ease-in;
    transform: rotate(180deg);
}

.expandable-toggler{
    display: none;
}

.expandable-toggler:checked ~ .tripHeader > .iconContainer >.icon{
    transform: rotate(0deg);
}
@use "styles/colors";
@use "styles/typography";

.container {
    color: colors.$kgo-color-neutral100;
    margin-top:1em;
    width: 65%;
    .label{
        margin-top: 16px;
        @extend .kgo-font-md300;
    }

    .mb34{
        margin-bottom: 34px;
    }

    .head{
        @extend .kgo-font-xlg600;
    }

    .side{
        flex-basis: 15%;
    }

    .left{
        @extend .side;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .middle{
        flex-basis:50%;
        flex-grow: 1;
        margin-left: 96px;
        margin-right: 96px;
    }

    .right{
        @extend .side;
    }

    .icon{
        font-size:60px !important;
        padding: 0px;
        cursor: pointer;
    }
  
}

.btnBar{
    margin-top: 48px;
	display:flex;
	justify-content:flex-end;
	margin-bottom: 24px;
}
.btnBar > * {
	margin:0px 5px 0px 5px;
}

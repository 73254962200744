@use "styles/colors";
@use "styles/typography";

.container{
    padding-top: 26px;
}


hr{
    height: 0px;
    border: 4px solid colors.$kgo-color-neutral300;
    margin:36px;
}

.headerText{
    margin-left: 29px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
}

.lastRow:last-child{
    margin-bottom: 16px;
}

.bannerContainer{
    margin:24px;
}

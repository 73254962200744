@use "styles/colors";
@use "styles/typography";

.container{
	max-width:424px;
}

.searchBarContainer{
	input{
		border: none;
		outline:none;
		margin-left:24px;
		margin-right:2px;
		width:100%;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
		color:black;
	}
	input::placeholder{
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
		/* kngo/color/neutral/neutral400 */
		color: #BBBBBB;
	}
	height:40px;
	box-shadow: 0px 2px 4px #CFCFCF;
	border-radius: 8px;
	display:flex;
	align-items:center;
	.icons{
		margin-left:auto;
		margin-right:16px;
		display:flex;
        cursor: pointer;
		&>*{
				margin-right:12px;
		}
	}
	.bar{
		width: 2px;
		height: 20px;
		background: #D9D9D9;
		border-radius: 8px;
		
	}
    .icon{
        color:colors.$kgo-color-base-blue600;
    }
}


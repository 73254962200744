@use "styles/colors";
@use "styles/typography";

.container{
    padding-top: 4px;
}

.headerText{
    margin-left: 29px;
    margin-top: 24px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
    margin-bottom: 32px;
}

.lastRow:last-child{
    margin-bottom: 16px;
}

.bannerContainer{
    margin:24px;
}

@use "styles/colors";
@use "styles/typography";

.headerCard{
	width:100%;
	background:white;
	border-radius:12px;
	/* kngo/shadow/neutral/onLight/1 */
	box-shadow: 0px 2px 4px #CFCFCF;
	.header{
		/* kngo/color/semantic/ℹ️info/info200 */
		background: #E8F4FD;
		margin:0px 44px 0px 44px;
		height:32px;
		border-radius: 0px 0px 12px 12px;
		display:flex;
		align-items:center;
		justify-content:center;
		color:#1175C9;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
	.infoContainer{
		margin-top:16px;
		margin-bottom:8px;
		height:100%;
		display:flex;
		justify-content:space-between;
		align-items:center;
		align-content:center;
		&>*{
			margin-left:40px;
			margin-right:40px;
		}
		.origin{
			display:flex;
			width: 100%;
			flex-basis: 5;
			.iconContainer{
				/* kngo/color/base/blue/blue600 */
				background: #0F4658;
				width:36px;
				height:55px;
				border-radius:4px;
				display:flex;
				position:relative;
				margin-right:12px;
				.icon{
					color:white;
                    @extend .center;
				}
			}
		}
		.originInfo{
			.originHeader{
				/* kngo/desktop/body/sm/700 */
				font-family: 'Inter';
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				/* kngo/color/base/blue/blue600 */
				color: #0F4658;
			}
			.originText{
				/* kngo/desktop/body/xlg/600 */
				font-family: 'Inter';
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 32px;
				/* kngo/color/base/blue/blue400 */
				color: #45859D;
			}
		}
		.bridge{
			/* Vector 43 */
			width: 20%;
			height: 0px;
			/* kngo/color/neutral/neutral500 */
			border: 1px dashed #696969;
			position:relative;
		}
		.bridge:after{
			content:'';
			border-radius:50%;
			height:15px;
			width:15px;
			color:1px dashed #696969;
			background: #696969;
			position:absolute;
			left:-20px;
			top:-8px;
		}
		.bridge:before{
			content:'';
			border-radius:50%;
			height:15px;
			width:15px;
			color:1px dashed #696969;
			background: #696969;
			position:absolute;
			right:-20px;
			top:-8px;
		}
		
	}
	.destiny{
	@extend .origin;
	.iconContainer{
		/* kngo/color/base/orange/orange600 */
		background: #FF5100 !important;
	}
	.originText{
		/* kngo/color/base/orange/orange600 */
		color: #FF5100 !important;
	}
	.originHeader{
		/* kngo/color/base/orange/orange700 */
		color: #C75004 !important;
	}
}
}

.center{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}

.arrowContainer{
	display:flex;
	justify-content:center;
}

.arrow {
	/* kngo/color/semantic/🎬action/action200 */
  border: solid #27677D;
  border-radius: 4px;
  border-width: 0 6px 6px 0;
  display: inline-block;
  padding: 10px;
  margin-bottom:24px;
  transition:all .2s linear;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.pointer{
	cursor:pointer;
}

.card{
	margin-bottom:24px;
}

.expandableToggler{
	display:none;
}

.expandableToggler:checked ~ .cardInfo .arrow{
	transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.expandableToggler:checked ~ .cardInfo > .content{
	transform:scaley(1);
	height:100%;
}

.content{
	margin-top:12px;
	transform:scaley(0);
	transition:all .0s ease-in;
	height:100px;
	height:0px;
	transform-origin:top;
}



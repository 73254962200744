@use "styles/colors";
@import "src/styles/_typography.scss";

.itemsContainer{
	display:flex;
	flex-direction:column;
	gap:12px;
	margin-bottom: 24px;
}

.item{
	max-width: 400px;
	background: colors.$kgo-color-neutral100;
	/* kngo/shadow/neutral/onLight/1 */
	box-shadow: 0px 2px 4px #CFCFCF;
	border-radius: 12px;
	display:flex;
	gap:24px;
	padding:24px;
	position:relative;
}

.idContainer{
	flex-basis:20%;
	height:100%;
	align-self:center;
	@extend .kgo-font-md300;
    color:black;
}
.infoContainer{
	flex-basis:80%;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	gap:16px;
}
.title{
	color: colors.$kgo-color-base-blue600;
	/* kngo/desktop/body/md/300 */
	@extend .kgo-font-md300;
	font-weight: 600;
}

.detailsContainer{
	display:flex;
	flex-direction:column;
	gap:4px;
	.detail{
		color: colors.$kgo-color-neutral700;
		font-weight: 600;
		font-size: 12px;
		line-height: 20px;
	}
}

.labelWrapper{
	display:flex;
	flex-direction:column;
	gap:6px;
	max-width:140px;
	label{
            color:black;
			/* kngo/desktop/body/xsm/600 */
			@extend .kgo-font-sm600;
		}
	input{
		width:100%;
		background-color:transparent;
		border:none;
		outline: none;
		color:black;
	}
	.inputWrapper{
		display:flex;
		height: 32px;
		border-radius: 8px;
		align-items:center;
		padding: 4px 4px 4px 4px;
		border: 1px solid colors.$kgo-color-neutral300;
		/* kngo/shadow/neutral/onLight/1 */
			box-shadow: 0px 2px 4px #CFCFCF;
		.suffix{
            color:black;
			padding:0px;
			margin:0px;
			background: colors.$kgo-color-base-blue100;
			border-radius: 4px;
			padding: 4px 2px;
			/* kngo/desktop/body/xsm/500 */
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;
		}
	}
}

.itemFormContainer{
	display:flex;
	gap:8px;
}

.warningText{
    color:red;
	@extend .kgo-font-xsm500;
  }

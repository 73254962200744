@use "styles/colors";
@use "styles/typography";

.divider{
    height: 0px;
    border: 2px solid colors.$kgo-color-neutral300;
    margin:40px;
}


.noSuggested{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}
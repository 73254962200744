@use "styles/colors";
@use "styles/typography";
@use "styles/inputs";

.container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.input-error {
    border: solid 1px colors.$error;
}

.KGOInput{
    height: 40px;
    margin-top: 16px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #45859D;
    border-radius: 8px;
    color: colors.$kgo-color-neutral800;
    padding-left:12px;
    width: 100%;
    @extend .kgo-font-md200;
}

.input-error:focus-within {
    outline: none !important;
    border: solid 2px colors.$error;
}

.error-text {
    margin-top: 8px;
    color: colors.$error;
    font-size: typography.$body-s;
}
.sucess-text{
    margin-top: 8px;
    color: colors.$kgo-color-semantic-success300;
    font-size: typography.$body-s;
}

.label{
    @extend .kgo-font-md400;
    color: colors.$kgo-color-neutral100
}

.KGOContainer {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 12px;
}

.KGOLabel{
    color:colors.$kgo-color-neutral600;
    margin-top: 4px;
    @extend .kgo-font-sm600;
}
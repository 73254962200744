@use "styles/colors";
@use "styles/typography";

.container {
    /*display: flex;*/
    background: colors.$kgo-color-background-onDark;
    color: colors.$kgo-color-neutral100;
    justify-content: center !important;
	display: flex !important;
	align-items: center !important;
	margin-top: 100px;
}

.popup{
	.popup-heading{
		@extend .kgo-desktop-heading3;
		color:colors.$kgo-color-neutral800
	}
	.popup-text{
		margin-top: 16px;
		@extend .kgo-font-md200;
		color:colors.$kgo-color-neutral700
	}
	.buttons{
		display: flex;
		justify-content: center;
		margin-top: 16px;
		>*{
			margin-left: 16px;
		}
	}
}


@use "styles/colors";
@use "styles/typography";
@use "styles/inputs";
.container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    width:auto;
}

.input-error:focus-within {
    outline: none !important;
    border: solid 1px colors.$error;
}

.error-text {
    margin-top: 10px;
    color: colors.$error;
    font-size: typography.$body-s;
}

.input-error {
    border: solid 1px colors.$error;
}

.input-error:focus {
    border-color: colors.$error;
}

@use "styles/colors";
@use "styles/typography";

.headerCard{
    box-shadow: 0px 2px 4px #CFCFCF;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 28px;
    border-radius: 12px;
    padding-top: 28px;
    z-index: 9999;
    background-color: white;
    .title{
        color:colors.$kgo-color-neutral800;
        margin-bottom: 24px;
        @extend .kgo-desktop-heading2;
    }
    .text{
        color:colors.$kgo-color-neutral700;
        @extend .kgo-font-md300;
    }
}

.headerCardDark{
    /* kngo/color/background/onDark */
    background: #081217;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 28px;
    border-radius: 12px;
    padding-top: 28px;
    z-index: 9999;
    .title{
        color:colors.$kgo-color-neutral100;
        margin-bottom: 24px;
        @extend .kgo-desktop-heading2;
    }
    .text{
        color:colors.$kgo-color-neutral100;
        @extend .kgo-font-md300;
    }
}

.noCompleted{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}
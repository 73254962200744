@use "styles/colors";
@use "styles/typography";

.popup {
    .popup-heading {
        @extend .kgo-desktop-heading3;
        color: colors.$kgo-color-neutral800
    }

    .popup-text {
        margin-top: 16px;
        @extend .kgo-font-md200;
        color: colors.$kgo-color-neutral700
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 16px;

        >* {
            margin-left: 16px;
        }
    }
}
@use "styles/colors";

.container {
    display: flex;
    justify-content: flex-start;
}

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    
}

.form-description{
    color: colors.$color-text-disabled;
}
@use "styles/colors";
@use "styles/typography";
.button {
    width: 100%;
    display: flex;
    border: none;
    border-radius: 80px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 11px 24px;
    p {
        margin: 0;
    }
}

.default {
    background-color: transparent;
    color: colors.$default;
    font-size: typography.$body-s;
    padding: 0;
}

.grey {
    color: colors.$default;
    background-color: transparent;
    font-size: typography.$body-m;
    border: solid 1px colors.$default;
}

.primary {
    color: white;
    border: 2px solid colors.$kgo-color-base-orange500;
	background-color:transparent;
    font-size: typography.$body-m;
}

.primary-filled {
    background-color: colors.$kgo-color-base-orange500;
    color: colors.$white;
    font-size: typography.$body-m;
}

.secondary {
    color: colors.$secondary;
    background-color: transparent;
    font-size: typography.$body-m;
    border: solid 1px colors.$secondary;
}

.secondary-filled {
    color: colors.$white;
    background-color: colors.$secondary;
    font-size: typography.$body-m;
}

.disable {
    background-color: colors.$color-button-disabled;
    cursor: default;
    color: colors.$white;
    font-size: typography.$body-m;
}

.primary-filled:hover,
.primary:hover {
    &:not(.no-border) {
        background-color: colors.$kgo-color-base-orange700;
        background: colors.$kgo-color-base-orange700;
        border: solid 1px colors.$kgo-color-base-orange700;
        color: colors.$white;
    }
}

.primary-hover{
    background-color: colors.$kgo-color-base-orange700;
    background: colors.$kgo-color-base-orange700;
    color: colors.$white;
}

.secondary-filled:hover,
.secondary:hover,
.grey:hover {
    &:not(.no-border) {
        background-color: colors.$secondary-hover;
        border: solid 1px colors.$secondary-hover;
        color: colors.$white;
    }
}

.hover {
    transition: background-color 0.4s, border 0.4s;
}

.no-border {
    border: none;
}

.square {
    border-radius: 8px;
}

.form-button{
    @extend .kgo-font-lg700;
    @extend .primary-filled;
    font-size: 1.25rem;
    margin: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 200px;
    height: 40px;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


.form-button:hover{
    @extend .primary-hover;
}
@use "styles/colors";
@use "styles/typography";

.container{
	margin-top: 0px;
}

.bg{
	width: 100%;
	height: 20vh;
	position: relative;
	img{
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		user-select: none;
	}
}

.title{
	position: absolute;
	color:white;
	bottom: 0px;
  	left: 0;
	margin-left: 29px;
	margin-bottom: 4px;
	@extend .kgo-desktop-heading1;
}

.breadCrumbContainer{
	position: absolute;
	top: 0px;
	margin-top: 24px;
	margin-left: 24px;
}
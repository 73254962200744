$body-s: 12px;
$body-m: 16px;
$header-xs: 18px;
$header-s: 20px;
$header-m: 24px;
$header-l: 34px;
$bold: bold;
$semi-bold: 600;

.kgo-desktop-heading1 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: bold;
}

.kgo-desktop-heading2 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: bold;
}

.kgo-desktop-heading3 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: bold;
}

.kgo-desktop-heading4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: bold;
}

.kgo-font-xlg{
    font-size: 1.5rem;
    line-height: 2rem; 
}

.kgo-font-xlg100{
    @extend .kgo-font-xlg;
    font-weight: 100;
}

.kgo-font-xlg200{
    @extend .kgo-font-xlg;
    font-weight: 200;
}

.kgo-font-xlg300{
    @extend .kgo-font-xlg;
    font-weight: 300;
}

.kgo-font-xlg400{
    @extend .kgo-font-xlg;
    font-weight: 400;
}


//kngo/desktop/body/xlg/semibold
.kgo-font-xlg600{
    @extend .kgo-font-xlg;
    font-weight: 600;
}

.kgo-font-lg{
    font-size: 1.25rem;
    line-height: 1.75rem; 
}

.kgo-font-lg300{
    @extend .kgo-font-lg;
    font-weight: 300;
}

.kgo-font-lg400{
    @extend .kgo-font-lg;
    font-weight: 400;
}

.kgo-font-lg600{
    @extend .kgo-font-lg;
    font-weight: 600;
}

.kgo-font-lg700{
    @extend .kgo-font-lg;
    font-weight: 700;
    
}

.kgo-font-lg800{
    @extend .kgo-font-lg;
    font-weight: 800;
}

.kgo-font-md{
    font-size: 1rem;
    line-height: 1.5rem; 
}

.kgo-font-md100{
    @extend .kgo-font-md;
    font-weight: 100;
}

.kgo-font-md200{
    @extend .kgo-font-md;
    font-weight: 200;
}

.kgo-font-md300{
    @extend .kgo-font-md;
    font-weight: 300;
}

.kgo-font-md400{
    @extend .kgo-font-md;
    font-weight: 400;
}

.kgo-font-sm{
    font-size: 0.875rem;
    line-height: 1.375rem; 
}

.kgo-font-sm600{
    @extend .kgo-font-sm;
    font-weight: 600;

}

.kgo-font-sm700{
    @extend .kgo-font-sm;
    font-weight: 700;
}

.kgo-font-sm800{
    @extend .kgo-font-sm;
    font-weight: 800;
}

.kgo-font-xsm{
    font-size: 0.75rem;
    line-height: 1.25rem; 
}

.kgo-font-xsm500{
    @extend .kgo-font-sm;
    font-weight: 500;
}

.kgo-font-xsm600{
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
}
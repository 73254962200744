@use "styles/colors";
@use "styles/typography";

.breadCrumb{
	height: 36px;
	width: fit-content;
	padding-left: 16px;
	padding-right: 16px;
	/* kngo/color/base/blue/blue700 */
	background: #002430;
	border-radius: 28px;
	display: flex;
	align-items: center;
}

.linkGroup{
	display: flex;
	gap:12px;
	width: 100%;
}

.linkContainer{
  display: flex;
  gap:12px;
  cursor: pointer;
}
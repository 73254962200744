@use "styles/colors";
@use "styles/typography";

.noSuggested{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}

.headerText{
    margin-left: 29px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
}

.searchBarContainer{
    margin-bottom: 26px;
    margin-left: 26px;
    margin-right: 26px;
    display: flex;
    justify-content: flex-end;
}
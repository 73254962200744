@use "styles/colors";
@use "styles/typography";

.addContactTitle {
  @extend .kgo-font-sm800;
  color: white;
  text-align: end;
  cursor: pointer;
}

.phoneWrapper{
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap:4px;
}

.divider {
  width: 100%;
  height: 0px;
  margin-bottom: 36px;
  padding: 0px !important;
  border: 1px solid colors.$kgo-color-base-blue300;
}
.inputWrapper {
  display: flex;
  height: 32px;
  padding: 0px 12px;
  margin-bottom: 12px;
  background: colors.$kgo-color-base-blue800;
  border: 1px solid colors.$kgo-color-base-blue500;
  box-shadow: 0px 4px 4px #020d0f;
  border-radius: 8px;
}

.formTitle {
  @extend .kgo-font-sm600;
  color: white;
  margin-bottom: 8px;
}
.warningText {
  color: red;
  @extend .kgo-font-xsm500;
  margin-top: -10px;
}

.contactForm {
  padding: 24px;
  background: colors.$kgo-color-base-blue700;
  border-radius: 8px;

  .title {
    color: colors.$kgo-color-neutral100;
    @extend .kgo-font-lg600;
  }
}
.bannerContainer {
  margin-top: 28px;
  margin-bottom: 24px;
}

@use "styles/colors";
@use "styles/typography";

.carrierContainer{
    background-color:colors.$kgo-color-base-blue800;
    border-radius: 12px;
    padding: 40px 24px 40px 24px;
}

.title{
    color: colors.$kgo-color-neutral100;
    @extend .kgo-desktop-heading3;
}

.carrierDetailContainer{
    margin-top: 28px;
    display: flex;
    gap:28px;
    position: relative;
    .initials{
        position: relative;
        background-color: colors.$kgo-color-base-blue400;
        min-width: 36px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        .initialsText{
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.detail{
    margin-bottom: 12px;
    position: relative;
}

.detailHeader{
    color: colors.$kgo-color-neutral100;
    @extend .kgo-font-xsm600;
    margin-bottom: 4px;
}

.detailLabel{
    position: relative;
    width: 100%;
    color: colors.$kgo-color-neutral100;
    @extend .kgo-font-md200;
    overflow-wrap: break-word;
}

.divBar{
    width: 100%;
    height: 2px;
    background-color: colors.$kgo-color-neutral300;
    margin-top: 32px;
    margin-bottom: 32px;
}

.contactForm{
    padding: 24px;
    background: colors.$kgo-color-base-blue700;
    border-radius: 8px;

    .title{
        color:colors.$kgo-color-neutral100;
        @extend .kgo-font-lg600;
    }
}

.infoBlock{
    padding: 8px 24px;
    display: flex;
    align-items: center;
    gap:12px;
    margin-top: 24px;
}

.unitInfo{
    padding: 8px 24px;
}

.hr{
    width: 100%;
}

.unitInfoContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap:32px;
    padding-left: 20px
}

.unitInfoTitle{
    @extend .kgo-desktop-heading3;
}

.availabilityIcon{
    width:14px;
    height:14px;
    color: colors.$kgo-color-base-blue300;
}

.availability{
    color:colors.$kgo-color-neutral100;
    @extend .kgo-font-md300;
}

.lastResponseIcon{
    width:14px;
    height:14px;
}

.blue300{
    color: colors.$kgo-color-base-blue300;
}

.warningColor{
    color: colors.$kgo-color-semantic-error200;
}

.lastResponseTitle{
color:colors.$kgo-color-neutral100;
@extend .kgo-font-md100;
}

.lastResponseLabel{
 color:colors.$kgo-color-neutral100;
 @extend .kgo-font-md200;
}

.unitTypeTitle{
    color:colors.$kgo-color-neutral100;
    @extend .kgo-font-md100;
    }

.unitTypeLabel{
    color:colors.$kgo-color-neutral100;
    @extend .kgo-font-md200;
}

.bannerContainer{
    margin-top: 28px;
    margin-bottom: 24px;
}

.buttonContainer{
    margin-top: 32px;
    display: flex;
    justify-content: center;
}

.addContactTitle{
    @extend .kgo-font-sm800;
    color: white;
    text-align: end;
    cursor: pointer;
}

.divider{
  width: 100%;
  height: 0px;
  margin-bottom: 36px;
  padding: 0px !important;
    border: 1px solid colors.$kgo-color-base-blue300;
}

input{
    width:100%;
    background-color:transparent;
    border:none;
    outline: none;
    color: white;
}

.inputWrapper{
    display:flex;
    height: 32px;
    padding: 0px 12px;
    margin-bottom: 12px;
    background: colors.$kgo-color-base-blue800;
    border: 1px solid colors.$kgo-color-base-blue500;
    box-shadow: 0px 4px 4px #020D0F;
    border-radius: 8px;
}

.formTitle{
    @extend .kgo-font-sm600;
    color:white;
    margin-bottom: 8px;
}

.warningText{
    color:red;
	@extend .kgo-font-xsm500;
    margin-top: -10px;
  }

  .popup{
	.popup-heading{
		@extend .kgo-desktop-heading3;
		color:colors.$kgo-color-neutral800
	}
	.popup-text{
		margin-top: 16px;
		@extend .kgo-font-md200;
		color:colors.$kgo-color-neutral700
	}
	.buttons{
		display: flex;
		justify-content: center;
		margin-top: 16px;
		>*{
			margin-left: 16px;
		}
	}
}

.availabilityTitle{
    @extend .kgo-font-sm600;
}

.zone{
    @extend .kgo-font-md300;
}


.lastResponseTitle{
    @extend .availabilityTitle;
}

.lastResponseLabel{
    @extend .zone;
}

.unitTypeTitle{
    @extend .availabilityTitle;
}

.unitTypeLabel{
    @extend .zone;
}


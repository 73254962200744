@use "styles/colors";
@use "styles/typography";
@use "styles/popup";
@use "styles/forms";

.noCompleted{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}

.ccpLabel{
    /* kngo/desktop/body/md/300 */
    margin-top: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    /* kngo/color/neutral/neutral800 */
    color: #1F1F1F;
}

.fieldContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 450px;
    .label{
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .text{
        color: black;
        /* kngo/color/neutral/neutral200 */
        background: #FBFBFB;
        /* kngo/color/neutral/neutral300 */
        border: 1px solid #E9E9E9;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 12px;
    } 
}

.groupField{
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 32px;
}

.buttonContainer{
    max-width: 450px;
}

.reasonContainer{
    position: relative;
    padding: 0px 24px;
    padding-bottom: 18px;
    min-height: 92px;
    max-width: 400px;
    /* kngo/color/semantic/🚨warning/warning100 */
    background: colors.$kgo-color-semantic-warning100;
    border-radius: 12px;
    padding-top: 14px;
    .title{
        color: colors.$kgo-color-neutral800;
        @extend .kgo-font-md300;
    }
    .label{
        position: relative;
        padding: 0px 12px;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        min-height: 32px;
        /* kngo/color/neutral/neutral200 */
        background: colors.$kgo-color-neutral200;;
        /* kngo/color/neutral/neutral300 */
        border: 1px solid colors.$kgo-color-neutral300;;
        border-radius: 8px;
        color:colors.$kgo-color-neutral600;
        @extend .kgo-font-xsm500;
    }
}

.form-container{
    margin-top: 24px;
    padding-top: 24px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px #CFCFCF;
    padding-bottom: 24px;
    padding-left: 10%;
    padding-right: 10%;
}

.rejectionBanner{
    margin-top: 32px;
    margin-bottom: 16px;
}

.reasonFieldContainer{
    max-width: 450px;
}
@use "styles/colors";
@use "styles/typography";

$sidebar-width: 358px;
$sidebar-width: 300px;

.content{
	margin-left:$sidebar-width;
	color:white;
	background-color: #fcfcfc;
	min-height: 100vh;
}

.sidebar{
	margin:0px;
	background-color:colors.$kgo-color-base-blue600;
	width:$sidebar-width;
	height:100%;
	position:fixed;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	align-items:center;
	
	.up{
		margin-top:50px;
        
		.profileImg{
		--width:52px;
		width:var(--width);
		height:var(--width);
		border-radius:50%;
		background-color:black;
		display:flex;
		align-items:center;
		justify-content:center;
		position:relative;
		left:50%;
		transform:translatex(-50%);

		.profileImgText{
			color:white;
			user-select:none;
		}
	}
	.upTitle{
			color:white;
			text-align:center;
			margin-top: 32px;
			@extend .kgo-desktop-heading1;
	}
	 }
	
	.mid{
		width:70%;	
        min-width: 50px;
		.navList{
			width:100%;
			position:relative;
			.item{
				cursor:pointer;
				margin-bottom:24px;
                padding-left: 24px;
				height:36px;
				color:white;
				.text{
					position:relative;
					width:100%;
					top:50%;
					transform:translatey(-50%);
				}
                &:hover{
                    background-color:colors.$kgo-color-base-blue500;
                    border-radius: 8px;
                }
			}
            .itemActive{
                @extend .item;
                    background-color:colors.$kgo-color-base-blue800;
                    border-radius: 8px;
            }
		}
        .icon{
            margin-right: 8px;
            font-size: 16px;
        }
	}
	
}
@use "styles/colors";

.container {
    /*display: flex;*/
    background: colors.$kgo-color-background-onDark;
    color: colors.$kgo-color-neutral100;
    justify-content: center !important;
}

.form-container {
    width: 540px;
}

.popup {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .popup-text {
        margin-top: 56px;
        margin-bottom: 20px;
    }
}
@use "styles/colors";
@use "styles/typography";

.input {
    @extend .kgo-font-md400;
    /*height: 40px;
    border: solid 1px #666666;
    border-radius: 4px;
    margin-top: 8px;
    padding: 9px 16px;*/
    /* input */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 14px;

    width: auto;
    height: 40px;

    /* kngo/color/base/blue/blue800 */
    background: #061820;

    /* kngo/color/base/blue/blue600 */
    border: 1px solid #0F4658;
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    color: colors.$kgo-color-neutral100;
}


.label{
    padding: 0px 0px 4px 4px;
    text-align: left;
}
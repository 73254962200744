@use "styles/colors";
@use "styles/typography";

.container{
	margin-top: 0px;
	padding: 12px;
}

.warning{
	background-color: colors.$kgo-color-semantic-warning200;
	border: 2px solid colors.$kgo-color-semantic-warning300;
	color:colors.$kgo-color-neutral800;
	border-radius: 8px;
	padding:12px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	@extend .kgo-font-sm600;

	.title{
		color:black;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}
	.icon{
		margin-right: 8px;
		font-size: 24px;
	}
	
	.iconContainer{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.info{
	background-color: colors.$kgo-color-semantic-info200;
	border: 2px solid colors.$kgo-color-semantic-info300;
	color:colors.$kgo-color-neutral100;
	border-radius: 8px;
	padding:12px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	@extend .kgo-font-sm600;

	.title{
		color:colors.$kgo-color-neutral100;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}
	.icon{
		margin-right: 8px;
		font-size: 24px;
		color:colors.$kgo-color-neutral100;
	}
	
	.iconContainer{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.error{
	background-color: colors.$kgo-color-semantic-error200;
	border: 2px solid colors.$kgo-color-semantic-error300;
	color:colors.$kgo-color-neutral100;
	border-radius: 8px;
	padding:12px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	@extend .kgo-font-sm600;

	.title{
		color:colors.$kgo-color-neutral100;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}
	.icon{
		margin-right: 8px;
		font-size: 24px;
		color:colors.$kgo-color-neutral100;
	}
	
	.iconContainer{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.success{
	background-color: colors.$kgo-color-semantic-success200;
	border: 2px solid colors.$kgo-color-semantic-success300;
	color:colors.$kgo-color-neutral800;
	border-radius: 8px;
	padding:12px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	@extend .kgo-font-sm600;

	.title{
		color:black;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}
	.icon{
		margin-right: 8px;
		font-size: 24px;
		color:colors.$kgo-color-neutral800;
	}
	
	.iconContainer{
		display: flex;
		justify-content: center;
		align-items: center;
	}
}




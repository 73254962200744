@use "styles/colors";
@use "styles/typography";

.large{
    @extend .kgo-font-lg800;
    border:none;
    height: 48px;
    width: 100%;
    @extend .kgo-font-lg800;
}

.medium{
    @extend .kgo-font-md400;
    border:none;
    height: 40px;
    width: 100%;
    @extend .kgo-font-md400;
}

.small{
    @extend .kgo-font-sm800;
    border:none;
    height: 36px;
    width: 100%;
    @extend .kgo-font-sm800;
}

.dark{
    color: white !important;
}

.primary-high{
    background-color: colors.$kgo-color-base-orange500;
    color: colors.$kgo-color-neutral100;
    border-radius: 8px;
    &:focus{
        border: 2px solid colors.$kgo-color-neutral600;
    }
    &:hover{
        background-color: colors.$kgo-color-base-orange700;
    }
    &:active{
        background-color: colors.$kgo-color-base-orange800;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral400;
    }
}

.secondary-high{
    background-color: colors.$kgo-color-base-blue500;
    color: colors.$kgo-color-neutral100;
    border-radius: 8px;
    &:focus{
        border: 2px solid colors.$kgo-color-neutral600;
    }
    &:hover{
        background-color: colors.$kgo-color-base-blue600;
    }
    &:active{
        background-color: colors.$kgo-color-base-blue800;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral400;
    }
}

.tertiary-high{
    background-color: colors.$kgo-color-base-blue500;
    border-radius: 8px;
    color:white;
    &:focus{
        border: 2px solid colors.$kgo-color-neutral600;
    }
    &:hover{
        background-color: colors.$kgo-color-base-blue400;
    }
    &:active{
        background-color: colors.$kgo-color-base-blue600;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral300;
        color:colors.$kgo-color-neutral500;
    }
}

.primary-medium{
    background-color: transparent;
    color: colors.$kgo-color-neutral800;
    border-width: 2px;
    border-style: solid;
    border-color:colors.$kgo-color-base-orange500;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        background-color: colors.$kgo-color-base-orange400;
    }
    &:hover{
        border-color: colors.$kgo-color-base-orange700;
    }
    &:active{
        border-color: colors.$kgo-color-base-orange800;
        background-color: transparent;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral400;
        color:colors.$kgo-color-neutral300;
        border:none;
    }
}

.secondary-medium{
    background-color: white;
    color: colors.$kgo-color-neutral800;
    border-width: 2px;
    border-style: solid;
    border-color:colors.$kgo-color-base-blue700;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        background-color: colors.$kgo-color-base-blue400;
    }
    &:hover{
        border:none;
        color:white;
        background-color: colors.$kgo-color-base-blue600;
    }
    &:active{
        border-color: colors.$kgo-color-neutral600;
        background-color: colors.$kgo-color-base-blue200;
        color: colors.$kgo-color-neutral800;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral400;
        color:colors.$kgo-color-neutral300;
        border:none;
    }
}

.tertiary-medium{
    background-color: white;
    color: colors.$kgo-color-neutral800;
    border-width: 2px;
    border-style: solid;
    border-color:colors.$kgo-color-base-blue500;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        background-color: colors.$kgo-color-base-blue300;
        color:white;
    }
    &:hover{
        border-color: colors.$kgo-color-base-blue400;
    }
    &:active{
        border-color: colors.$kgo-color-base-blue600;
        background-color: colors.$kgo-color-base-blue100;
        color: colors.$kgo-color-neutral800;
    }
    &:disabled{
        background-color: colors.$kgo-color-neutral400;
        color:colors.$kgo-color-neutral300;
        border:none;
    }
}

.primary-low{
    background-color: white;
    color: colors.$kgo-color-neutral800;
    border-width: 2px;
    border-style: solid;
    border-color:white;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        color: colors.$kgo-color-base-orange400;
        border-radius: 0px;
    }
    &:hover{
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 8px;
        text-decoration-color: colors.$kgo-color-base-orange600;
    }
    &:active{
        border-color: colors.$kgo-color-base-orange700;
        background-color: white;
        text-decoration: none;
        border-radius: 8px;
    }
    &:disabled{
        color:colors.$kgo-color-neutral400;
        text-decoration: none;
        border:none;
    }
}

.secondary-low{
    background-color: white;
    color: colors.$kgo-color-neutral800;
    border-width: 2px;
    border-style: solid;
    border-color:white;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        border-radius: 0px;
        color: colors.$kgo-color-base-blue500;
    }
    &:hover{
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 8px;
        text-decoration-color: colors.$kgo-color-base-blue600;
    }
    &:active{
        border-color: colors.$kgo-color-base-blue600;
        background-color: white;
        text-decoration: none;
        border-radius: 8px;
    }
    &:disabled{
        color:colors.$kgo-color-neutral400;
        text-decoration: none;
        border:none;
    }
}

.tertiary-low{
    background-color: white;
    color: colors.$kgo-color-base-blue700;
    border-width: 2px;
    border-style: solid;
    border-color:white;
    border-radius: 8px;
    &:focus{
        border-color: colors.$kgo-color-neutral600;
        border-radius: 0px;
        color: colors.$kgo-color-base-blue300;
    }
    &:hover{
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 8px;
        text-decoration-color: colors.$kgo-color-base-blue400;
    }
    &:active{
        border-color: colors.$kgo-color-base-blue300;
        background-color: white;
        text-decoration: none;
        border-radius: 8px;
    }
    &:disabled{
        color:colors.$kgo-color-neutral400;
        text-decoration: none;
        border:none;
    }
}
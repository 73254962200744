@use "styles/colors";
@use "styles/typography";

.container{
    padding-top: 26px;
}


.headerText{
    margin-left: 29px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
}

.searchBarContainer{
    margin-bottom: 26px;
    margin-left: 26px;
    margin-right: 26px;
    display: flex;
    justify-content: flex-end;
}

.bannerContainer{
    margin: 24px;
}
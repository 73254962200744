@use "styles/typography";
@use "styles/colors";


.KGOTitle{
    @extend .kgo-font-lg400;
    color:colors.$kgo-color-neutral700;
    margin-right:auto;
}

.container {
    margin: auto auto 0;
    color: colors.$kgo-color-neutral100;

    .margin-title {
        margin-bottom: 32px;
        margin-top: 40px;
    }
}

.action {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.full-container-width {
    width: 100%;
}

.large-container-width {
    width: 90%;
}

.medium-container-width {
    width: 80%;
}

.small-title {
    font-size: typography.$header-s;
    font-weight: typography.$bold;
}

.medium-title {
    font-size: typography.$header-m;
    font-weight: typography.$bold;
}

.large-title {
    @extend .kgo-desktop-heading1;
    color: colors.$kgo-color-neutral100;
    text-align: center;
}

@media (min-width: 1300px) {
    .large-title {
        font-size: typography.$header-l;
    }
}
@use "styles/colors";

.container {
    display: flex;
    justify-content: center;
}

.form-container {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.form-description{
    color: colors.$color-text-disabled;
}

.buttonContainer{
    margin-top: 32px;
}
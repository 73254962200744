.full-width {
    width: 100%;
}

.half-width {
    width: 48%;
}

.medium {
    width: 66%;
}

.small {
    width: 30%;
}
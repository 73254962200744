@use "styles/colors";
@use "styles/typography";
@use "styles/popup";
@use "styles/forms";
.noCompleted{
    color:black;
    text-align: center;
    img{
        width: 30%;
        margin-bottom: 12px;
    }
    .header{
        @extend .kgo-font-lg600;
        margin-bottom: 16px;
    }
    .desc{
        @extend .kgo-font-md200;
        margin-bottom: 16px;
    }
}

.ccpLabel{
    /* kngo/desktop/body/md/300 */
    margin-top: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    /* kngo/color/neutral/neutral800 */
    color: #1F1F1F;
}

.fieldContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 450px;
    .label{
        color: black;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
    .text{
        color: black;
        /* kngo/color/neutral/neutral200 */
        background: #FBFBFB;
        /* kngo/color/neutral/neutral300 */
        border: 1px solid #E9E9E9;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        padding: 0px 12px;
    } 
}

.groupField{
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 32px;
}


.buttonContainer{
    max-width: 450px;
}
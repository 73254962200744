@use "styles/colors";
@use "styles/typography";

.KGOLabel{
    color:black;
    margin-top: 4px;
    @extend .kgo-font-md200;
}

.KGOcontainer {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
}

.KGO-input-container {
    height: 40px;
    margin-top: 16px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* kngo/color/neutral/neutral100 */
    background: transparent;
    /* kngo/color/base/blue/blue400 */
    border: 1px solid #45859D;
    border-radius: 8px;
    margin-bottom: 12px;
    width: 100%;

    select {
        cursor: pointer;
        padding: 9px 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        border: none;
        background: transparent;
        @extend .kgo-font-md400;
        color: colors.$kgo-color-neutral800;
        width: 100%;

        option{
            @extend .kgo-font-md400;
            color: colors.$kgo-color-neutral800;
            background: colors.$kgo-color-neutral100;
            border: none;
            border: 1px solid colors.$kgo-color-base-blue700;
            margin: 15px;
            padding: 15px;
        }
    
    }

    
    .KGOarrow {
        color: black;
        right:4px;
        position:absolute;
        z-index: -9999;
    }
}

.warningText{
    color:red;
  }

.asyncSelect {
    cursor: pointer;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background: transparent;
    margin-top: 18px;
    @extend .kgo-font-md400;
    color: colors.$kgo-color-neutral800;
    background: #FFFFFF;
    /* kngo/color/base/blue/blue400 */
    border: 0.5px solid #45859D;
    border-radius: 8px;

    option{
        @extend .kgo-font-md400;
        color: red;
        background: colors.$kgo-color-neutral800;
        border: none;
        border: 1px solid colors.$kgo-color-base-blue700;
        margin: 15px;
        padding: 15px;
    }

}

.input-container {
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    background: colors.$kgo-color-base-blue800;
    /* kngo/color/base/blue/blue600 */
    border: 1px solid colors.$kgo-color-base-blue700;
    /* kngo/shadow/base/onDark/1 */
    box-shadow: 0px 4px 4px #020D0F;
    border-radius: 8px;

    select {
        width: 100%;
        cursor: pointer;
        border: none;
        padding: 9px 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        background: transparent;
        @extend .kgo-font-md400;
        color: colors.$kgo-color-neutral400;

        option{
            @extend .kgo-font-md400;
            color: colors.$kgo-color-neutral400;
            background: colors.$kgo-color-base-blue800;
            border: none;
            border: 1px solid colors.$kgo-color-base-blue700;
            margin: 15px;
            padding: 15px;
        }
    
    }

    
    .arrow {
        right: 10px;
        color: white;;
    }
}
@use "styles/colors";
@use "styles/typography";

    .origen{
        display: flex;
        flex-direction: column;
        align-content: center;
        text-align: left;

        .title{
            @extend .kgo-font-xsm600;
        }

        .from .title{
            color:colors.$kgo-color-base-blue600;
        }

        .from .text{
            color:colors.$kgo-color-base-blue500;
            @extend .kgo-font-sm800;
        }

        .from{
            margin-bottom: 12px;
            display: flex;
            align-items: center;
        }

        .arrive .title{
            color:colors.$kgo-color-base-orange700;
        }

        .arrive .text{
            @extend .kgo-font-md400;
            color: colors.$kgo-color-base-orange600;
        }

        .arrive{
            display: flex;
            align-items: center;
        }

        .iconContainer{
            margin-right: 16px;
            max-width: 10px;
        }
        
        .icon{
            font-size: 12px;
        }

        .from .icon{
            color: colors.$kgo-color-base-blue600;
        }

        .arrive .icon{
            color: colors.$kgo-color-base-orange600;
        }

        .from .iconContainer::after{
            content: "";
            position: relative;
            left: -50%;
            top: 30px;
            border: none;
            border-left: 1px dashed colors.$kgo-color-neutral500;
          
        }
    }

    .header{
        color: colors.$kgo-color-base-blue600;
        @extend .kgo-font-sm700;
        margin-bottom: 8px;
        text-align: center;
        .icon{
            color: colors.$kgo-color-base-blue400;
            margin-right: 8px;
        }
    }

    .unidad{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fecha{
       @extend .unidad; 
    }

    .disp{
        @extend .unidad; 
     }

     .detalles{
        @extend .unidad; 
     }

    .subHeader{
        color: colors.$kgo-color-neutral700;
        max-width: 150px;
        @extend .kgo-font-sm800;
        text-align: center;
    }
    .description{
        color: colors.$kgo-color-neutral700;
        max-width: 150px;
        @extend .kgo-font-md200;
        text-align: center;
    }

    .disp{
        background-color: colors.$kgo-color-base-blue100;
        padding: 0px;
        box-sizing: border-box;
        border: 7px solid colors.$kgo-color-base-blue100;
        border-radius: 12px;
        
    }

    .detalles{
        .text{
            color: #0058E1;
            font-weight: 600;
            font-size: 12px;
            line-height: 28px;
            text-decoration: underline;
            text-decoration-color: #0058E1;
            text-underline-offset: 4px;
            position: relative;
            top: 40px;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }



.suggested{
    background-color: colors.$kgo-color-neutral100 !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    
    .disp{
        background-color: colors.$kgo-color-base-blue100 !important;
        box-sizing: border-box !important;
        border: 7px solid colors.$kgo-color-base-blue100 !important;
        border-radius: 12px !important;
        .header{
            color:colors.$kgo-color-base-blue600 !important;
        }
        .description{
            color: colors.$kgo-color-neutral700 !important; 
        }
    }
    .detalles .text{
        background: #FF6116 !important;
        border-radius: 8px !important;
        color: white !important;
        @extend .kgo-font-sm800;
        text-decoration: none !important;
        position: relative;
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

@use "styles/colors";
@use "styles/typography";

.container{
    display:flex;
    justify-content: flex-start;
    padding-right: 24px;
    padding-top: 40px;
}

.card{
    display: flex;
    max-width: 236px;
    margin-left: 28px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 8px #CFCFCF;
    border-radius: 12px;
    padding: 24px;
    width: 300px;
    cursor: pointer;

    .title{

        display: flex;
        align-items: center;
        .titleText{
            color:#1F1F1F;
            @extend .kgo-desktop-heading4;
        }
    }
    
    .subtitle{
        color: black;
        display: flex;
        margin-top: 8px;
        margin-left: 2px;
        align-items: center;

        >*{
            flex-grow: 1;
        }

        .indicator{
            @extend .kgo-desktop-heading1;
            color:colors.$kgo-color-base-blue500;
            margin-right: 12px;
        }

        .text{
            width: auto;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color:colors.$kgo-color-neutral500;
        }
    }
}

.iconContainer{
    background-color: colors.$kgo-color-base-blue800;
    margin: 0px;
    height: 84px;
    width: 84px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    .icon{
        color: white;
        height: 40px;
        width: 40px;
    }
}

@use "styles/colors";
@use "styles/typography";



.divider{
    position: relative;
    top:-80px;
    display: flex;
    z-index: 999;
    margin-left: 24px;
    margin-right: 24px;
    gap:24px;
}

.column{
    display: flex;
    flex-direction: column;
}

.columnOne{
    @extend .column;
    flex-grow: 1;
}

.columnTwo{
    @extend .column;
    max-width: 500px;
    min-width: 400px;
}

.tripInfoContainer{
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap:12px;
}

.activeTripHeader{
    color:colors.$kgo-color-neutral800;;
    @extend .kgo-desktop-heading3;
    text-align: center;
    margin-bottom: 12px;
}

.headerCard{
    box-shadow: 0px 2px 4px #CFCFCF;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 28px;
    border-radius: 12px;
    padding-top: 28px;
    z-index: 9999;
    background-color: white;
    .title{
        color:colors.$kgo-color-neutral800;
        @extend .kgo-desktop-heading2;
    }
    .text{
        color:colors.$kgo-color-neutral700;
        @extend .kgo-font-md300;
    }
}



.headerText{
    margin-left: 29px;
    color:colors.$kgo-color-neutral700;
    @extend .kgo-font-lg400;
}

.title{
    @extend .kgo-desktop-heading3;
    color:colors.$kgo-color-neutral700;
    margin-bottom: 20px;
}

.bor{
    color:colors.$kgo-color-neutral700;
}

.popup{
	.popup-heading{
		@extend .kgo-desktop-heading3;
		color:colors.$kgo-color-neutral800
	}
	.popup-text{
		margin-top: 16px;
		@extend .kgo-font-md200;
		color:colors.$kgo-color-neutral700
	}
	.buttons{
		display: flex;
		justify-content: center;
		margin-top: 16px;
		>*{
			margin-left: 16px;
		}
	}
}

.buttonContainer{
    margin-right: 28px;
}

@media only screen and (max-height: 799px) {
    .divider {
        top:-40px;
    }
  }
